<template>
  <v-dialog v-model="dialog" persistent max-width="450px">
    <v-card :disabled="loading" :loading="loading">
      <v-card-title>
        Fazla Mesai Onaylama
        <v-spacer />
        <v-btn depressed icon @click="onClickCancel">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text class="pt-3" v-if="overtime">
        <v-flex xs12 mt-2>
          {{ overtime.overtime.employee.firstName }} {{ overtime.overtime.employee.lastName }}
          <b>
            {{ overtime.overtime.workplan.date | formattedDate }} tarihinideki vardiyası için
            {{ overtime.overtime.overtimeInMinutes }} dk
          </b>
          fazla mesai talebini onaylamak istiyor musunuz ?
        </v-flex>

        <v-flex xs12>
          <v-text-field v-model.trim="overtimeApprovalDTO.note" name="note" :label="$t('global.note')" />
        </v-flex>
        <v-flex xs12>
          <v-radio-group row mandatory v-model="overtimeApprovalDTO.approved">
            <v-radio color="primary" :value="false" :label="$t('leave.request.denied')" />
            <v-radio color="primary" :value="true" :label="$t('leave.request.approved')" />
          </v-radio-group>
        </v-flex>
      </v-card-text>
      <v-card-actions class="dialog-footer">
        <v-spacer></v-spacer>
        <v-btn depressed @click.stop="onClickSave">{{ $t("buttons.save") }}</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
  export default {
    name: "confirm-dialog",
    props: {
      value: Boolean,
      data: Object
    },
    data: () => ({
      dialog: false,
      loading: false,
      overtime: null,
      overtimeApprovalDTO: {
        approved: false,
        note: null
      }
    }),
    watch: {
      value: {
        handler(status) {
          this.dialog = status;
        },
        immediate: true
      },
      data: {
        handler(overtime) {
          this.overtime = overtime;
        },
        deep: true
      }
    },
    methods: {
      onClickCancel() {
        this.overtimeApprovalDTO.approved = null;
        this.overtimeApprovalDTO.note = null;
        this.overtime = null;
        this.$emit("close");
      },
      onClickSave() {
        this.loading = true;
        this.$api.overtimeService
          .approve(this.overtime.id, this.overtimeApprovalDTO)
          .then(data => {
            if (data) {
              this.$emit("success");
              this.overtimeApprovalDTO.note = null;
              this.overtimeApprovalDTO.note = null;
              this.overtime = null;
            }
          })
          .catch(e => {
            console.log("e", e);
          })
          .then(() => {
            this.loading = false;
          });
      }
    }
  };
</script>

<style scoped></style>
